<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>개인정보처리방침</li>
						</ul>
					</div>
					<h2 class="page_title">개인정보처리방침</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="policy_section">
						<div class="policy_group">
							<p>와이즈앤퓨어(이하 회사라 합니다)가 제공하는 쉽고 편한 중소기업형 빅데이터 플랫폼 서비스 와퓨 서비스(와퓨 서비스)는 이용자의 개인정보와 관련하여 개인정보보호법등 국내 개인정보 관련 법령을 준수합니다. 회사는 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.</p>
							<p>회사는 개인정보처리방침을 개정하는 경우 해당서비스 공지사항(또는 개별공지)을 통하여 공지할 것입니다. 본 개인정보처리방침은 와퓨 서비스에 대하여 적용되며, 회사의 다른 서비스에 대하여는 별도의 개인정보처리방침이 적용될 수 있습니다.</p>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">1. 개인정보의 수집 및 이용목적</h3>
							<p>회사는 와퓨 서비스 제공을 위하여 필요한 최소한의 범위 내에서 아래와 같은 목적으로 정보주체인 이용자의 동의를 얻어 개인정보를 수집, 이용하고 있습니다.</p>
							<ol class="ol_deciaml_02">
								<li>
									<span>1)</span>
									<strong>와퓨 회원 가입 및 관리</strong>
									회원제 서비스에 대한 이용자의 가입의사 확인 및 개인의 식별, 회원 자격 유지 및 서비스 부정 이용 방지, 무분별한 가입 및 가입횟수 제한, 서비스의 원활한 이용, 공지사항 전달, 탈퇴 의사의 확인
								</li>
								<li>
									<span>2)</span>
									<strong>와퓨 서비스 제공 및 고객 문의 해결</strong>
									서비스 및 콘텐츠 제공, 유료 서비스 정기결제, 이용자의 문의 시 신원확인 및 문의 내용 확인, 답변내용 전달
								</li>
								<li>
									<span>3)</span>
									<strong>마케팅 및 광고에의 활용(본 항목은 별도 활용 동의를 얻은 경우에만 활용합니다)</strong>
									이벤트 및 광고성 정보 제공 및 이용자의 참여 기회 제공, 서비스 이용 빈도 및 통계학적 자료 수집, 광고 기획
								</li>
								<li>
									<span>4)</span>
									<strong>법적 분쟁 시 증거로 활용</strong>
								</li>
							</ol>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">2. 수집하는 개인 정보의 항목</h3>
							<p>회사가 위 목적에 따라 수집하는 개인정보는 다음과 같습니다.</p>
							<ol class="ol_deciaml_02">
								<li>
									<span>1)</span>
									<strong>와퓨 홈페이지를 통한 서비스 신청시</strong>
									필수항목 : 이름, 회사명, 핸드폰번호
								</li>
								<li>
									<span>2)</span>
									<strong>소셜 계정을 통한 회원가입 시(해당 소셜계정 운영사의 개인정보 제3자 제공을 통하여 수집되는 정보)</strong>
									필수 항목 : 이름, 이메일주소<br>
									※ 구글연동으로만 회원가입이 가능합니다.
								</li>
								<li>
									<span>3)</span>
									<strong>서비스 이용 과정에서 추가 수집되는 정보</strong>
									<ul>
										<li>거래정보 : PG사에서 안전하게 수집하고 회사는 직접 수집하지 않습니다.</li>
									</ul>
								</li>
								<li>
									<span>4)</span>
									<strong>회사는 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보 (인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.</strong>
								</li>
							</ol>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">3. 개인정보의 보유 및 이용기간</h3>
							<p></p>
							<ol class="ol_deciaml_02">
								<li>
									<span>1)</span>
									<strong>회사는 원칙적으로 이용자의 개인정보 수집목적 및 이용목적 내에서만 활용하고 목적이 달성되면 그 정보를 지체 없이 파기합니다.</strong>
								</li>
								<li>
									<span>2)</span>
									<strong>제 1항에도 불구하고, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 해당 기간 동안 안전하게 보관합니다.</strong>
									<ul>
										<li>계약 또는 청약철회 등에 관한 기록 : 보존기간 5년</li>
										<li>대금결제 및 재화 등의 공급에 관한 기록 : 보존기간 5년</li>
										<li>소비자의 불만 또는 분쟁처리에 관한 기록 : 보존 기간 3년</li>
										<li>웹사이트 방문기록 : 보존 기간 3개월</li>
									</ul>
								</li>
								<li>
									<span>3)</span>
									<strong>개인정보의 파기 방법은 아래와 같습니다.</strong>
									<ul>
										<li>종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각</li>
										<li>전자적 파일형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제</li>
									</ul>
								</li>
							</ol>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">4. 개인정보의 제3자 제공</h3>
							<p>회사는 이용자의 개인정보를 사전 동의 없이 제3자에게 제공하지 않습니다. 다만 정보 주체의 동의가 있거나, 법률의 특별한 규정에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">5. 개인 정보의 처리 위탁</h3>
							<ol class="ol_deciaml_02">
								<li>
									<span>1)</span>
									<strong>회사는 서비스 이행을 위하여 아래와 같이 개인정보의 처리 업무를 외부 전문업체에 위탁하여 운영하고 있습니다.</strong>
									<div class="tbl">
										<table>
											<thead>
											<tr>
												<th>수탁업체</th>
												<th>위탁업무 내용</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>KG이니시스</td>
												<td>정기결제정보</td>
											</tr>
											<tr>
												<td>GOOGLE CLOUD</td>
												<td>빅데이터 플랫폼 인프라 제공</td>
											</tr>
											</tbody>
										</table>
									</div>
								</li>
								<li>
									<span>2)</span>
									<strong>회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재 위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</strong>
									※ 해당업체가 변경될 경우 웹사이트의 공지사항 또는 개별고지 방법을 통해 고지하겠습니다.
								</li>
							</ol>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">6. 쿠키의 운영 및 활용</h3>
							<ol class="ol_deciaml_02">
								<li>
									<span>1)</span>
									<strong>회사의 서비스에서는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)'를 운영합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 회원의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.</strong>
								</li>
								<li>
									<span>1)</span>
									<strong>회사의 서비스에서는 다음과 같은 목적으로 쿠키를 사용합니다.</strong>
									<ul>
										<li>회원과 비회원의 접속 빈도나 방문 시간 등을 분석하고 이용자의 취향과 관심분야를 파악하여 타겟(Target) 마케팅 및 서비스 개편 등의 척도로 활용합니다.</li>
										<li>와퓨에서 진행하는 각종 이벤트에서 응모 기회를 부여하고 개인의 관심 분야에 따라 차별화 된 정보를 제공하기 위한 자료로 이용됩니다.</li>
										<li>
											회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다.
											<ul>
												<li>
													쿠키 설치 허용 여부를 지정하는 방법 [Internet Explorer 6.0 이상의 버전을 사용하고 있는 경우]<br>
													[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.<br>
													[개인정보 탭]을 클릭합니다.<br>
													[개인정보보호 수준]을 설정하시면 됩니다.<br>
												</li>
												<li>회원께서 쿠키 설치를 거부하셨을 경우 웹 사이트 서비스 제공에 어려움이 있습니다.</li>
											</ul>
										</li>
									</ul>
								</li>
							</ol>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">7. 개인정보 보호 책임자</h3>
							<ol class="ol_deciaml_02">
								<li>
									<span>1)</span>
									<strong>회사는 회원의 개인정보를 보호하기 위하여 개인정보보호 책임자, 관리자 및 처리자를 지정하였습니다. 회원은 아래의 개인정보보호 책임자에게 개인정보보호 관련 사항을 문의할 수 있습니다.</strong>
									<ul>
										<li>
											개인정보보호 책임자<br>
											성 명 : 유규성<br>
											직 위 : 이랜드이노플 CISO<br>
											전자우편 : liugs@elandinnople.com<br>
										</li>
										<li>
											개인정보 처리부서<br>
											부서명 : 이랜드이노플 데이터플랫폼<br>
											전자우편 : WISENPURE@eland.co.kr<br>
										</li>
									</ul>
								</li>
								<li>
									<span>2)</span>
									<strong>기타 개인정보침해에 대한 신고 또는 상담이 필요한 경우에는 아래 기관으로 문의하시기 바랍니다.</strong>
									<ul>
										<li>개인정보침해신고센터 - http://privacy.kisa.or.kr / 국번 없이 118</li>
										<li>대검찰청 사이버수사과 - http://www.spo.go.kr / 국번 없이 1301</li>
										<li>경찰청 사이버안전국 - http://cyberbureau.police.go.kr / 국번 없이 182</li>
									</ul>
								</li>
							</ol>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">8. 만 14세 미만 아동의 경우 회사의 서비스 가입이 불가합니다.</h3>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">9. 개인정보 보호를 위한 기술적 / 제도적 관리</h3>
							<ol class="ol_deciaml_02">
								<li>
									<span>1)</span>
									<strong>회사는 회원의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다</strong>
									<ul>
										<li>개인정보처리 시스템에 대한 접근권한을 개인정보 보호 책임자, 개인정보 취급자에게만 부여하고, 이들에 대한 인사이동이 있는 경우, 접근권한을 변경, 말소합니다.</li>
										<li>개인정보 취급자로 하여금 외부의 정보통신망을 통하여 개인정보처리 시스템에 접속할 수 없도록 합니다.</li>
										<li>외부의 불법적인 접근제한 및 침해방지를 위한 시스템을 사용하고, 접속기록을 2년간 보관합니다.</li>
										<li>접속기록의 위 변조 방지를 위하여 월 1회 이상 정기적으로 확인, 감독하고, 접속기록은 2년 이상 보관하며, 별도의 저장장치에 보관하면서 정기적으로 백업을 시행합니다.</li>
										<li>회사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(Secure Socket Layer)를 채택하여 각종 정보를 암호화하여 운영합니다.</li>
										<li>해킹 등에 의해 이용자의 개인정보가 유출되는 것을 방지하기 위해, 외부로부터의 허가 받지 않은 접근을 차단하기 위한 2중 방화벽(일반방화벽, 홈페이지 전용방화벽)을 사용하고 있으며, 각 서버는 침입탐지/방어 시스템에 의하여 24시간 모니터링 되고 있습니다.</li>
										<li>개인정보에 대한 인쇄, 미리 보기, 파일생성 등 방지조치를 하여 출력을 할 수 없도록 합니다.</li>
										<li>출력, 복사의 경우, 이와 관련된 각종 사항을 기록하고 개인정보관리책임자로부터 승인을 받도록 합니다.</li>
									</ul>
								</li>
								<li>
									<span>2)</span>
									<strong>회사는 개인정보취급직원을 최소한으로 제한하고 담당직원에 대한 수시교육을 통하여 본 방침의 준수를 강조하고 있으며, 이와 관련된 문제가 발견될 경우 바로 시정조치하고 있습니다.</strong>
								</li>
								<li>
									<span>3)</span>
									<strong>회사는 회원의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 아니하며, 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 주민등록번호와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다. 그 외 내부관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 회사는 즉각 회원에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</strong>
								</li>
							</ol>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">10. 광고성 정보 전송</h3>
							<ol class="ol_deciaml_02">
								<li>
									<span>1)</span>
									<strong>회사는 이용자의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다.</strong>
								</li>
								<li>
									<span>2)</span>
									<strong>회사는 상품정보 안내 등 온라인 마케팅을 위해 광고성 정보를 전자우편 등으로 전송하는 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이 회원이 쉽게 알아볼 수 있도록 조치합니다.</strong>
									<ul>
										<li>전자우편의 제목란: (광고) 또는 (성인광고)라는 문구를 제목란의 처음에 빈칸 없이 한글로 표시하고 이어서 전자우편 본문란의 주요 내용을 표시합니다.</li>
										<li>전자우편의 본문란: 회원이 수신거부의 의사표시를 할 수 있는 전송자의 명칭, 전자우편 주소, 전화번호 및 주소를 명시하되, 회원이 수신거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및 영문으로 각각 명시합니다.</li>
									</ul>
								</li>
								<li>
									<span>3)</span>
									<strong>다음과 같이 청소년에게 유해한 정보를 전송하는 경우 (성인광고) 문구를 표시합니다.</strong>
									<ul>
										<li>
											본문란에 다음 각 항목의 어느 1에 해당하는 것이 부호,문자,영상 또는 음향의 형태로 표현된 경우(해당 전자우편의 본문란에는 직접 표현되어 있지 아니하더라도 수신자가 내용을 쉽게 확인할 수 있도록 기술적 조치가 되어 있는 경우를 포함한다)에는 해당 전자우편의 제목란에 "(성인광고)" 문구를 표시합니다.<br>
											<ol class="ol_deciaml_03">
												<li>
													<span>가)</span>
													청소년(19세 미만의 자를 말합니다. 이하 같습니다.) 에게 성적인 욕구를 자극하는 선정적인 것이거나 음란한 것
												</li>
												<li>
													<span>나)</span>
													청소년에게 포악성이나 범죄의 충동을 일으킬 수 있는 것
												</li>
												<li>
													<span>다)</span>
													성폭력을 포함한 각종 형태의 폭력행사와 약물의 남용을 자극하거나 미화하는 것
												</li>
												<li>
													<span>라)</span>
													청소년보호법에 의하여 청소년 유해 매체물로 결정 고시된 것
												</li>
											</ol>
										</li>
									</ul>
								</li>
							</ol>
						</div>
						<div class="policy_group">
							<h3 class="policy_title">11. 고지의 의무</h3>
							<p>현 개인정보처리방침은 2023년 11월 02일에 제정되었으며 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 또는 수정이 있을 시에는 개정 7일전부터 홈페이지 등의 공지란을 통해 고지할 것입니다</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "privacy"
}
</script>

<style scoped>

</style>